import { MegaportError, MegaportSDK } from '@megaport/api-sdk'
import { ElNotification } from 'element-plus'
import PrimeVue from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Lara from '@primevue/themes/lara'

import { getI18n } from '@/i18n/SetupI18n.js'
import integrations, { setupIntegrations } from '@/third-party-integrations/integrations.js'
import { captureZodParseError } from '@/utils/CaptureSentryError'
import '@/directives/permissions'
import '@/utils/demoData'
import store from '@/store/Store.js'
import router from '@/router'
import { mpApp, mpApp_$t } from '@/mpApp'
import { appConfiguration, setAppConfiguration } from '@/appConfiguration.js'
import { X_APP } from '@/Globals'

import '@/polyfills.js'

import 'vue-cal/dist/vuecal.css'
// This will load all the appropriate variables and overrides, and export the variables for use in the app
import '@/styles/export-variables.scss'
// Set up all our classes. Note that for whitelabel builds, this will point to
// src/whitelabels/WHITELABEL_FOLDER and for normal builds, it will point to src/styles, so if you want
// to include the normal styles in your whitelabel, you will need to import them from src/styles/classes.scss.
// Note that if you want to just use the default styles for the whitelabel, just don't have a classes.scss file
// in your whitelabel folder.
import 'SCSS_CLASSES_PATH/classes.scss'

// This is used by DLR to enable them to directly log in as a particular user
// by passing in a token in the URL (See ENG-14340).
const setTokenByURI = location.pathname.match(/token\/([a-z0-9-]+)/i)
if (setTokenByURI) {
  localStorage.setItem('_token', setTokenByURI[1])
}

const MpPreset = definePreset(Lara, {
  primitive: {
    // Generated using https://tints.dev/ with a base color of #229DF5 at 500, altering lightness in the range 90-20
    megaportPrimary: {
      50: '#BBE1FC',
      100: '#ACDAFB',
      200: '#8ACBFA',
      300: '#68BCF8',
      400: '#46ADF7',
      500: '#229DF5',
      600: '#0B8DEA',
      700: '#0978C8',
      800: '#0864A6',
      900: '#064F84',
      950: '#054470',
    },
  },
  semantic: {
    primary: {
      50: '{megaport.primary.50}',
      100: '{megaport.primary.100}',
      200: '{megaport.primary.200}',
      300: '{megaport.primary.300}',
      400: '{megaport.primary.400}',
      500: '{megaport.primary.500}',
      600: '{megaport.primary.600}',
      700: '{megaport.primary.700}',
      800: '{megaport.primary.800}',
      900: '{megaport.primary.900}',
      950: '{megaport.primary.950}',
    },
  },
})

// Dynamically import environment file
// File can be local root (/public folder) or external URL (e.g. http://someurl/environment.js)
import(/* @vite-ignore */ `${import.meta.env.BASE_URL}environment.js`).then(async environmentJs => {
  // Sanity check that environment.js actually contains something
  if (!environmentJs.default) {
    console.error('Unable to mount application: Missing environment settings!')
    return
  }
  setAppConfiguration(environmentJs.default)

  // Setup API-SDK
  const sdk = new MegaportSDK(appConfiguration.apiUrl, import.meta.env.VITE_SEND_CREDENTIALS === 'true', X_APP)

  // Setup Third Party Integrations
  setupIntegrations(appConfiguration)

  // Wait for i18n to be ready before starting the app
  const i18n = await getI18n()

  // Register plugins
  mpApp.use(router)
  mpApp.use(store)
  mpApp.use(i18n)

  mpApp.use(PrimeVue, {
    theme: {
      preset: MpPreset,
      options: {
        darkModeSelector: '.dark',
      },
    },
  })

  // General API error handler.
  // NOTE: Return true (error has been handled) if you want to indicate to other error handlers to skip processing the error.
  sdk.apiErrorCallback = megaportError => {
    // This matches versioned logout endpoints - /v1/logout, /v2/logout, /v20/logout, etc
    const versionedLogoutEndpointRegex = /^\/v[0-9]+\/logout$/

    // Handle failed auth (401), but only when it has not failed calling the logout endpoint.
    if (megaportError.status === 401 && !versionedLogoutEndpointRegex.test(megaportError.requestedUrl)) {
      const path = mpApp.config.globalProperties.$route.path
      const pathWhiteList = ['/signup', '/user-profile', '/mfa', '/post-login', '/aws-marketplace']

      // Paths here will handle the error in a different manner, so ignore.
      if (pathWhiteList.includes(path)) return false

      // This handles some dynamic paths, so not listed above.
      // /login /login/username/:username /loginas/:targetUsername? /login/help
      if (path.startsWith('/login') || path.startsWith('/onboard')) return false

      if (import.meta.env.VITE_BYPASS_AUTHENTICATION !== 'true') {
        // Logout the user (which redirects to /login) and display error.
        // Previously this was just redirecting, but it wasn't clearing localStorage
        // or triggering any of the other cleanups done by logout, which caused issues.
        // So we now dispatch logout and trigger all of its side effects on 401s.
        mpApp.config.globalProperties.$store
          .dispatch('Auth/logout')
          .catch(error => {
            // If we get a MegaportError here, it means that the logout call to the API failed.
            // In this case, we are most likely dealing with an unauthenticated user, so calling
            // logout again would be pointless and would lead to an infinite (or at least a lengthy) loop.
            // Instead, we just ensure we clear all user-related state and redirect to the login page.
            if (error instanceof MegaportError) {
              // Clear integration user information
              integrations.salesforceChat.logout()
              integrations.postHog.clearUser()
              integrations.segment.clearUser()
              // Trigger the onLogout side-effects
              mpApp.config.globalProperties.$store.dispatch('onLogout')
              // Clear API-SDK session
              sdk.clearSession()
            }
          })
          .finally(() => {
            ElNotification.error({
              title: mpApp_$t('authentication.please-login'),
              message: mpApp_$t('authentication.failed-login'),
              duration: 10000,
            })
          })

        return true
      }
    }

    // Any other errors are unhandled
    return false
  }

  // Callback for logging API response parsing errors in API-SDK
  sdk.parsingErrorCallback = captureZodParseError

  // Mount the app
  mpApp.mount('#mp')
})
