import sdk from '@megaport/api-sdk'

import { deepClone } from '@/helpers.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { randomNumber } from '@/utils/RandomNumber.js'
import config from '@/appConfiguration.js'
import { mpApp_$t } from '@/mpApp.js'

// Initial state
const coreState = {
  marketplaceData: [],
  loadMarketplaceProfilesTimer: null,
  providerTypes: [],
  isLoading: false,
}

const coreGetters = {
  /**
   * Get the marketplace profile for the current user's company.
   * @param {object} state The state object for the store.
   * @param {Object} _getters The getters object for the store
   * @param {object} rootState The root state object for the store.
   * @returns {object} The marketplace profile for the current user's company.
   */
  marketplaceUserProfile(state, _getters, rootState) {
    if (rootState.Company.data?.companyUid && state.marketplaceData) {
      return state.marketplaceData.find(profile => rootState.Company.data.companyUid === profile.companyUid)
    }
    return null
  },
}

const actions = {
  /**
   * Load all the marketplace profiles, and as a side effect, load my own profile.
   * @param {object} context The store context
   */
  loadMarketplaceProfiles(context) {
    context.commit('updateData', [])
    context.commit('updateLoading', true)

    return new Promise(resolve => {
      sdk.instance
        .marketplace()
        .get(true)
        .then(marketplaceProfiles => {
          const newData = marketplaceProfiles
            .filter(profile => profile.companyName)
            .map(profile => {
              profile._logoImage = 'url(/fallback-images/mp-placeholder.png)'
              profile._logo = '/fallback-images/mp-placeholder.png'

              if (profile.image === 'true') {
                const suffix = `?r=${randomNumber()}`
                const companyImageAPI = config.marketplaceMediaUrl
                profile._logoImage = `url(${companyImageAPI}/${profile.companyUid}${suffix})`
                profile._logo = `${companyImageAPI}/${profile.companyUid}${suffix}`
              }

              // Strip out surrounding quotes
              if (
                profile.companyBio &&
                profile.companyBio.charAt(0) === '"' &&
                profile.companyBio.charAt(profile.companyBio.length - 1) === '"'
              ) {
                profile.companyBio = profile.companyBio.slice(1, -1)
              }

              // Convert the services object into an array
              if (profile?.services) {
                profile.services = Object.entries(profile.services).map(([key, value]) => {
                  return {
                    ...value,
                    productUid: key,
                  }
                })
              } else {
                profile.services = []
              }

              return profile
            })
          context.commit('updateData', newData)
          context.dispatch('getUserMarketplaceProfile')

          // There is something weird going on in vitest where this triggers a vuex mutation warning for
          // Marketplace.loadMarketplaceProfilesTimer.
          if (import.meta.env.VITE_RUNTIME_ENV !== 'test') {
            const timerId = setTimeout(
              () => {
                context.dispatch('loadMarketplaceProfiles')
              },
              60 * 60 * 4 * 1000,
            ) // 4 hours
            context.commit('updateLoadMarketplaceProfilesTimer', timerId)
          }

          return resolve()
        })
        .catch(e => {
          captureSentryError(e)
        })
        .finally(() => {
          context.commit('updateLoading', false)
        })
    })
  },

  /**
   * Load the marketplace profile for the current user.
   * @param {object} context The store context
   */
  getUserMarketplaceProfile(context) {
    context.commit('updateLoading', true)

    sdk.instance
      .marketplace()
      .profile()
      .then(profile => {
        // Ensure the user's own profile is always in the marketplace
        if (!profile || !profile.companyName) return
        profile._logoImage = 'url(/fallback-images/mp-placeholder.png)'
        profile._logo = '/fallback-images/mp-placeholder.png'

        if (profile.image === 'true') {
          const suffix = `?r=${randomNumber()}`
          const companyImageAPI = config.marketplaceMediaUrl
          profile._logoImage = `url(${companyImageAPI}/${profile.companyUid}${suffix})`
          profile._logo = `${companyImageAPI}/${profile.companyUid}${suffix}`
        }

        // Convert the services object into an array
        if (profile?.services) {
          profile.services = Object.entries(profile.services).map(([key, value]) => {
            return {
              ...value,
              productUid: key,
            }
          })
        } else {
          profile.services = []
        }

        const index = context.state.marketplaceData.findIndex(p => profile.companyUid === p.companyUid)

        if (index === -1) {
          context.commit('appendProfile', profile)
        } else {
          // Make sure the data we have is up to date.
          context.commit('updateProfile', {
            index,
            profile,
          })
        }
      })
      .catch(error => {
        // A 404 error is expected if the user doesn't have a profile
        if (error.status !== 404) {
          console.error(error)
        }
      })
      .finally(() => {
        context.commit('updateLoading', false)
      })
  },

  /**
   * Update the marketplace profile for the current user.
   * The payload send back to the API needs to match exactly what was sent to us.
   * @param {object} context The store context
   * @param {object} updatedProfile The updated profile data
   */
  updateMarketplaceProfile(context, updatedProfile) {
    context.commit('updateLoading', true)

    // Make sure it's a clean object
    const workingProfile = deepClone(updatedProfile)

    // Remove any privately used fields
    delete workingProfile._logoImage
    delete workingProfile._logo
    if (workingProfile.image === 'true') {
      delete workingProfile.image
    }

    // Trim fields before sending to the API
    // The fields excluded here are taken care of with validation
    const fieldsToTrim = ['companyName', 'companyBio', 'street1', 'street2', 'city', 'state', 'postcode', 'country']
    fieldsToTrim.forEach(field => {
      if (workingProfile[field]) {
        workingProfile[field] = workingProfile[field].trim()
      }
    })

    // Convert the services back to an object with the productUid as the key because the API is stupid
    workingProfile.services = {}

    updatedProfile.services.forEach(service => {
      workingProfile.services[service.productUid] = {
        marketplaceVisible: service.marketplaceVisible,
        title: service.title.trim(),
        description: service.description?.trim(),
        contactEmail: service.contactEmail?.trim(),
        serviceTypes: service.serviceTypes,
        metadata: service.metadata, // Is this needed??
      }
    })

    sdk.instance
      .marketplace()
      .updateProfile(workingProfile)
      .then(result => {
        const index = context.state.marketplaceData.findIndex(profile => result.companyUid === profile.companyUid)
        if (index !== -1) {
          context.commit('updateProfile', {
            index,
            profile: result,
          })
        } else {
          context.commit('appendProfile', workingProfile)
        }

        context.commit(
          'Notifications/notifyMessage',
          {
            title: mpApp_$t('general.success-updating', {
              thing: mpApp_$t('menu.marketplace-profile'),
            }),
            message: mpApp_$t('marketplace.company-profile', {
              company: workingProfile.companyName,
            }),
            type: 'success',
          },
          { root: true },
        )

        // Double check the profile being updated is the user's own profile
        // Probably not needed
        if (context.rootState.Company.data && context.rootState.Company.data.companyUid === result.companyUid) {
          context.dispatch('getUserMarketplaceProfile')
        }
      })
      .catch(err => {
        // TODO: Improve error processing
        context.commit(
          'Notifications/notifyMessage',
          {
            title: mpApp_$t('general.error-updating', {
              thing: mpApp_$t('menu.marketplace-profile'),
            }),
            message: err.message || err.data.message,
            type: 'error',
          },
          { root: true },
        )
      })
      .finally(() => {
        context.commit('updateLoading', false)
      })
  },

  /**
   * Get the provider types for the marketplace.
   * @param {object} context The store context
   */
  async getProviderTypes(context) {
    await sdk.instance
      .marketplace()
      .providerTypes()
      .then(types => {
        context.commit('updateProviderTypes', types)
      })
      .catch(e => {
        captureSentryError(e)
      })
  },
}

const mutations = {
  /**
   * Reset the marketplace related data that was relevant to the logged in user.
   * @param {object} state The local state
   */
  logout(state) {
    state.marketplaceData = []
    state.providerTypes = []

    if (state.loadMarketplaceProfilesTimer) {
      clearTimeout(state.loadMarketplaceProfilesTimer)

      state.loadMarketplaceProfilesTimer = null
    }
  },
  /**
   * Updates the list of all marketplace profiles with new data.
   * @param {object} state State for this part of the store.
   * @param {object[]} data An array containing all marketplace profiles.
   */
  updateData(state, data) {
    state.marketplaceData = data
  },
  /**
   * Pushes a new profile to the list of all marketplace profiles.
   * @param {object} state State for this part of the store.
   * @param {object} profile The profile to append.
   */
  appendProfile(state, profile) {
    state.marketplaceData.push(profile)
  },
  /**
   * Update the profile at the given index with the new profile data.
   * @param {object} state State for this part of the store.
   * @param {object} payload Object containing the index and the new profile data.
   */
  updateProfile(state, payload) {
    const { index, profile } = payload
    const existingData = state.marketplaceData[index]
    state.marketplaceData[index] = {
      ...existingData,
      ...profile,
    }
  },
  /**
   * Update the timer used to reload the marketplace profiles.
   * @param {object} state State for this part of the store.
   * @param {number} newValue The new timer value.
   */
  updateLoadMarketplaceProfilesTimer(state, newValue) {
    state.loadMarketplaceProfilesTimer = newValue
  },
  /**
   * Update the provider types state for the marketplace.
   * @param {object} state State for this part of the store.
   * @param {object[]} types The new provider types to set.
   */
  updateProviderTypes(state, types) {
    state.providerTypes = types
  },
  /**
   * Update the isLoading state.
   * @param {object} state State for this part of the store.
   * @param {object} newValue New value for the isLoading state.
   */
  updateLoading(state, newValue) {
    state.isLoading = newValue
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
